//
// Accordion
//

// Base
.accordion {
	// According heading
	.accordion-header {
		cursor: pointer;
	}

	// Icon toggle mode
	&.accordion-icon-toggle {
		// Accordion icon expaned mode
		.accordion-icon {
			display: flex;
			flex-shrink: 0;
			transition: $transition-base;
			transform: rotate(90deg);
			align-items: center;
			justify-content: center;

			i,
			.svg-icon {
	    		color: $primary;
	    	}
		}

		// Accordion icon collapsed mode
		.collapsed {
			.accordion-icon {
				transition: $transition-base;
				transform: rotate(0);

				i,
				.svg-icon {
		    		color: $text-muted;
		    	}
			}
		}
	}

	// Reset accordion item border
	&.accordion-borderless {
		// According item
		.accordion-item {
			border: 0;
		}
	}

	// Reset accordion item border, border radiues and background color
	&.accordion-flush {
		// According item
		.accordion-item {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}	
}
